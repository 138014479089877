export default {
  synthetic: {
    groups: {
      popular: {
        mode: "withAll",
        filters: [
          "facilities.truck_parking",
          "facilities.loyalty",
          "facilities.super_wash",
          "facilities.open_24_hours",
          "facilities.rewe_to_go",
          "facilities.bistro",
          "products.lpg",
          "products.ultimate",
          "facilities.pay_at_pump_supported",
        ],
      },
      products: {
        mode: "withAll",
        filters: [
          "products.lpg",
          "products.natural_gas",
          "products.truck_diesel",
          "products.ultimate",
          "products.ad_blue",
          "products.electric_charging",
          "products.hvo",
        ],
      },
      facilities: {
        mode: "withAll",
        filters: [
          "facilities.recup",
          "facilities.motorway_site",
          "facilities.accepts_toll_payments",
          "facilities.cash_point",
          "facilities.loyalty",
          "facilities.open_24_hours",
          "facilities.rewe_to_go",
          "facilities.shop",
          "facilities.electric_charging",
          "facilities.pay_at_pump_supported",
          "facilities.too_good_to_go",
          "facilities.ryd",
        ],
      },
      car_wash: {
        mode: "withAll",
        filters: [
          "facilities.super_wash",
          "facilities.car_wash_plant",
          "facilities.super_boxes",
          "facilities.vda",
          "facilities.jet_wash",
        ],
      },
    },
    canonical: true,
  },
}
