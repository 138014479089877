export default {
  distance: {
    value: "{distance, number, distance} {unit}",
    onlyUnit: "{unit}",
    unit: {
      miles: "km",
    },
  },
  search: {
    input_placeholder: "Search for a location...",
    suggestions: "Suggestions",
  },
  info_window: {
    directions: {
      button: "Get Directions",
    },
    website_button: "Zu den Kraftstoffpreisen",
    services: {
      title: "Services",
    },
    fuel_prices: {
      title: "Fuel Prices",
    },
    opening: {
      title: "Opening Hours",
    },
    opening_hours: {
      open_twentyfourhours: "Open 24 hours",
      mon: "Montag",
      tue: "Dienstag",
      wed: "Mittwoch",
      thu: "Donnerstag",
      fri: "Freitag",
      sat: "Samstag",
      sun: "Sonntag",
      title: "Opening hours",
      period: "{start, time, short} – {end, time, short}",
    },
  },
  locations_list: {
    title: "Nearby Stations",
  },
  traffic_button: {
    shown: "Verkehr AN",
    hidden: "Verkehr AUS",
  },
  watchlist: {
    add: "Zur Watchlist hinzufügen",
    remove: "Von der Watchlist entfernen",
  },
  saved_routes: {
    title: "Gespeicherte Routen",
    list: {
      title: "Gespeicherte Routen",
    },
    save_modal: {
      title: "Route sichern ",
      placeholder: "Route Name",
      error: " Es ist ein Fehler aufgetreten",
      cancel: "Cancel",
      save: "Route sichern ",
    },
    save_button: {
      text: "Route sichern ",
    },
  },
  geolocation: {
    tooltip: {
      text: "Your current location",
      error: "Location not available",
    },
  },
  opening_hours: {
    twenty_four_hour: "24 Stunden geöffnet",
    open: "Open Now",
    closed: "Closed Now",
    undefined: " ",
  },
  directions: {
    route: {
      avoid_highways: "Autobahn vermeiden",
      avoid_tolls: "Mautstellen vermeiden",
      show_stations_on_route: "Show stations along route",
      find: "Get directions",
      new: "Routenplaner",
      title: "Routenoptionen",
      edit: "Edit Route",
      title_corridor: "Suche nach Entfernung von Tankstellen entlang der Route",
    },
    placeholder: {
      destination: "Nach",
      origin: "Von",
      waypoint: "Via",
    },
    help: {
      remove: "Remove",
      switch_waypoints: "Switch from and to",
      add_waypoint: "Ziel hinzufügen",
    },
  },
  itinerary: {
    title: "Ziel hinzufügen",
    from: "Von",
    to: "Nach",
    totals: {
      full: "Total {durationText} ({distanceText})",
      distance: "{distance, number, distance} {unit}",
      duration:
        "`{weeks, plural, =0 {} =1 {# wk} other {# wks}}{days, plural, =0 {} =1 {# day} other {# days}}{hours, plural, =0 {} =1 {# hr} other {# hrs}}{minutes, plural, =0 {} =1 {# min} other {# mins}}`",
    },
  },
  filters: {
    title: "Filter",
    clear: "Clear Filters",
    title_applied: "Filters ({count} applied)",
    popular: {
      title: "Popular Filters",
    },
    food: {
      title: "Food",
    },
    car_wash: {
      title: "Autowäsche",
    },
    facilities: {
      title: "Services",
    },
    products: {
      title: "Spezielle Kraftstoffe",
    },
  },
  general_buttons: {
    back: "Back",
  },
  facilities: {
    aral_store: "Aral Store",
    bp_connect_store: "BP-Connect-Store",
    bunker_site: "Tank",
    motorway_site: "Autobahntankstelle",
    accepts_routex_cards: "Annahme von Routex",
    accepts_toll_payments: "Mautstation",
    reminder_service: "Erinnerungsservice",
    car_wash: "Waschanlage",
    cash_point: "Geldautomat",
    electric_charging: "Ladesäulen",
    disabled_facilities: "Behindertengerechte Einrichtungen",
    high_speed_pump: "Hochleistungssäule",
    lorry_and_truck_terminal: "LKW-Terminal",
    lotto: "Lotto",
    toilet: "Toilette",
    pump_rewards: "Pump Rewards",
    cards_codes: "Cards and Codes",
    truck_parking: "Autohof",
    truck_wash: "LKW-Waschanlage",
    truck_suitable_site: "LKW-geeigneter Standort",
    wifi: "WLAN",
    loyalty: "PAYBACK",
    car_rental: "Car Rental",
    ruc: "Road User Charges",
    trailers: "Trailer Hire",
    bonus_club: "BP Bonusclub",
    go_box: "Go Box",
    workshop: "Workshop",
    truck_only: "Truckstop",
    super_wash: "Aral SuperWash",
    car_wash_plant: "Waschstraßen",
    super_boxes: "SB Waschplätze",
    vda: "VDA Konform Siegel",
    jet_wash: "Cabriopflege",
    special_car_wash: "SUV Pflege",
    open_24_hours: "24 Stunden geöffnet",
    automatic_site: "BP express",
    swappa_bottle: "Swappa Bottle LPG",
    tyre_service: "Reifenservice",
    uta: "UTA",
    dkv: "DKV",
    bike_friendly: "Biker-freundlich",
    swipa: "SWIPA",
    fuel_master: "FuelMaster",
    shower: "Shower",
    washing_machine: "Washing Machine",
    dry_cleaning: "Dry Cleaning",
    tires_pump_up: "Air/Water",
    pay_at_pump_supported: "Fuel&Go",
    rewe_to_go: "REWE To Go",
    bakery_bistro: "Bäckerei-Bistro",
    bistro: "PetitBistro",
    ms_simply_food: "M&S Simply Food",
    petit_bistro: "PetitBistro",
    restaurant: "Restaurant",
    shop: "Shop",
    shop_open_24_hours: "Shop 24 h",
    supermarket: "Supermarkt",
    tobacco: "Tabak",
    alcohol: "Alkohol",
    wild_bean_cafe: "Wild Bean Café",
    merkur: "MERKUR inside",
    pnp_express: "PnP Express",
    nandos: "Nandos",
    mcdonalds: "McDonalds",
    steers: "Steers",
    wimpy: "Wimpy",
    chicken_licken: "Chicken Lickin",
    kfc: "KFC",
    rock_n_rooster: "Rock \\\\\\'n Rooster",
    fresh_pastry: "Fresh Pastry",
    bottle_gas: "BP Flaschenglas",
    mobile_enabled: "Mobile Enabled",
    recup: "RECUP",
    ultra_high_flow_diesel: "Ultra High Flow Diesel",
    too_good_to_go: "Too Good To Go",
    ryd: "ryd pay",
    automatische_diesel_station: "Automatische Diesel Station (ADS)",
  },
  products: {
    ad_blue_cannister: "AdBlue Kanister",
    ad_blue_pump: "AdBlue-Pumpe",
    aa_smart_fuel: "AA Smartfuel",
    lpg: "LPG",
    electric_charging: "Strom",
    auto_gas: "Autogas",
    natural_gas: "Erdgas",
    red_diesel: "Roter Diesel",
    truck_diesel: "LKW Diesel",
    ultimate: "Ultimate",
    ultimate_102: "BP Ultimate 102",
    ultimate_diesel: "Ultimate Diesel",
    ultimate_unleaded: "BP Ultimate bleifrei",
    super_e5: "Aral Super E5",
    super_98: "Aral Super 98",
    ad_blue: "AdBlue",
    ultimate_98: "Super Plus Additiviert",
    unleaded_91: "Normalbenzin",
    unleaded_95: "Eurosuper",
    regular_diesel: "Diesel",
    ultimate_super: "BP Ultimate Super",
    diesel_add: "Diesel Additiviert",
    unleaded_91_add: "Benzin Additiviert",
    unleaded_95_add: "Eurosuper additiviert",
    unleaded_98: "Super Plus",
    diesel_frost: "Alpin-Diesel ",
    futura_fuel_oil: "Heizoel Extraleicht Futura",
    euro_95: "Euro 95 with 10% Ethanol",
    fuel_oil: "Heizoel Extraleicht",
    ultimate_diesel_50: "Aral SuperDiesel",
    ultimate_diesel_500: "Ultimate Diesel 500",
    ultimate_ulp_93: "Ultimate ULP 93",
    ultimate_bp_clear_super: "BP Ultimate Clear Super",
    regular_92: "Regular 92",
    hvo: "HVO (LKW)",
  },
  car_wash: {
    super_wash: "Aral SuperWash",
    car_wash_plant: "Waschstraßen",
    super_boxes: "SB Waschplätze",
    vda: "VDA Konform Siegel",
    special_car_wash: "SUV Pflege",
  },
}
